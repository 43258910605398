<template>
  <div class="no-access">
    <h1>Welcome to EnglishScore!</h1>
    <p>Your permissions have not yet been updated. Please contact your manager.</p>
    <router-link to='/'>
      <!-- <es-btn>
        Go to the CMS home page
        <fa icon="chevron-right" />
      </es-btn> -->
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'NoPermissions',
}
</script>
<style lang="scss" scoped>
.no-access {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  & h1 {
    color: $dark-indigo;
  }
  & a {
    text-decoration: none;
    color: $dark-indigo;
    font-family: $headline;
  }
}
</style>