import { render, staticRenderFns } from "./NoPermissions.vue?vue&type=template&id=2adf9976&scoped=true&"
import script from "./NoPermissions.vue?vue&type=script&lang=js&"
export * from "./NoPermissions.vue?vue&type=script&lang=js&"
import style0 from "./NoPermissions.vue?vue&type=style&index=0&id=2adf9976&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2adf9976",
  null
  
)

export default component.exports